@import '../../utils/styles/mixins.scss';
@import '../../utils/styles/variables.scss';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0 40px;
  border-top: 1px solid $stroke-default;
  background: $app;
  height: $footer-height-mobile;
  
  @include onTablet {
    flex-direction: row;
    height: $footer-height;
  }

  &_helpTextContainer {
    display: flex;  
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 6px;
    padding: 6px 0;
    
    @include onTablet {
      padding: 26px 0;
      margin-bottom: 0;
    }
  }

  &_questionmark {
    width: 16px;
    height: 16px;

    &:hover {
      cursor: pointer;
    }
  }

  &_helpText {
    font-size: 12px;
    transition: all 0.3s ease;
  }
  
  &_link {
    &:hover > div > p {
      color: $primary;
    }  
  }
}