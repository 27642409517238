@import '../../../utils/styles/variables.scss';

.drag_and_drop {
  border-radius: 4px;
  border: 1px solid $stroke-default;
  width: 100%;
  height: 80px;
  padding: 10px;

  &__upload_icon {
    width: 16px;
    height: 16px;
  }

  &__input {
    display: none;
  }

  &__title {
    font-size: 12px;
    text-align: center;
  }

  &__uploaded_file {
    min-width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;

    &_title {
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__wrapper {
    cursor: pointer;
  }
}