@import './variables.scss';

* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

html {
  font-size: 16px;
  font-weight: 400;
  color: $font-active;
  background: $app;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

ul, ol, p, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

ol {
  margin-left: 15px;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  padding: 0;
}
