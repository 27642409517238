.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid rgb(252,188,5);
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
  animation: spin 0.75s linear infinite;

  &--sm {
    min-width: 24px;
    min-height: 24px;
    border-width: 4px;
    border-top-width: 4px;
  }
  
  &--xsm {
    min-width: 16px;
    min-height: 16px;
    border-width: 2px;
    border-top-width: 2px;
  }
}

.fullScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 15;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
