.BaseTable__row-cell, .BaseTable__header-cell {
  width: 100%;
  border-right: 1px solid rgb(238, 238, 238);
}

.BaseTable__table-main {
  border-radius: 4px;
}

.BaseTable__row-cell-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.BaseTable__header-cell-text {
  font-size: 14px;
}

.BaseTable, .BaseTable__body, .BaseTable__header {
  max-width: 1240px;
}

