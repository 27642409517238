.surveys_editor_header {
  &__add_text {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    width: fit-content;
    height: fit-content;
  }

  &__button_icon {
    width: 16px;
    height: auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__add_image {
    &_label {
      width: 16px;
      height: 16px;
    }

    &_input {
      display: none;
      width: 16px;
      height: 16px;
    }
  }
}