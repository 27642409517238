.step_with_description {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  &__description {
    font-size: 14px;
    line-height: 24px;
  }
}