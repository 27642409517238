.smart_link_table_item {
  &:hover {
    cursor: pointer;
  }

  &__data {
    width: 100%;
    max-width: 606px;
    overflow: hidden;
  }
}