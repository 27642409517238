@import '../../utils/styles/variables.scss';

.create_action_btn {
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  border: 2px solid $stroke-default;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  padding: 0;
  
  &__top_container {
    height: 140px;
    background-color: #F5F5F5;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: all 0.2s ease-in-out;

    .create_action_btn:hover & {
      background-color: #BBCED7;
    }

    .create_action_btn:active & {
      background-color: #55849C;
    }
  }

  &__bottom_container {
    height: 60px;
  }

  &__image {
    width: 60px;
    height: 60px;
  }

  &:hover {
    transform: translate(0, -2px);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
  &:active {
    transform: translate(0);
    border-color: #55849C;
    box-shadow: none;
  }
}
