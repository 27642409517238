@import '../../utils/styles/variables.scss';

.preview_right_sidebar {
  min-width: 310px;
  height: calc(100vh - $editor-header - $survey-page-header);
  border-left: 1px solid $stroke-default;
  padding: 0 20px;
  z-index: 1;
  background-color: $app;

  &__title {
    margin-top: 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}