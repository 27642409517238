@import '../../../utils/styles/variables.scss';

.preview_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid $stroke-default;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  background-color: $app;
  padding: 0 16px;
  cursor: pointer;
  outline: none;

  &__icon {
    width: 16px;
    height: auto;
  }

  &__title {
    font-size: 12px;
  }

  &__active {
    background-color: $light-green;
    font-weight: bold;
  }
}