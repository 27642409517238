@import '../../utils/styles/typography.scss';
@import '../../utils/styles/mixins.scss';

.mailchimp_accounts_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    @extend %main-title-s;
    @extend %main-title-l;

    margin: 0 0 40px;
  }

  &__table_wrapper {
    width: 100%;
    max-width: 1200px;
    height: 40vh;
  }

  &__add_account {
    margin-top: 30px;
    min-width: 280px;

    & > span {
      font-size: 14px;
    }
    
    @include onTablet {
      margin-top: 60px;

      & > span {
        font-size: 16px;
      }
    }
  }
}