@font-face {
  font-family: 'Century Gothic';
  src: url('../../assets/fonts/CenturyGothic.ttf') format('ttf'),
      url('../../assets/fonts/CenturyGothic-Bold.ttf') format('ttf'),
      url('../../assets/fonts/CenturyGothic-BoldItalic.ttf') format('ttf'),
      url('../../assets/fonts/CenturyGothic-Italic.ttf') format('ttf'),
}

@font-face {
  font-family: 'Verdana';
  src: url('../../assets/fonts/Verdana.ttf') format('ttf'),
      url('../../assets/fonts/Verdana-Bold.ttf') format('ttf'),
      url('../../assets/fonts/Verdana-BoldItalic.ttf') format('ttf'),
      url('../../assets/fonts/Verdana-Italic.ttf') format('ttf'),
}

@font-face {
  font-family: 'Comic Sans MS';
  src: url('../../assets/fonts/ComicSansMS.ttf') format('ttf'),
      url('../../assets/fonts/ComicSansMS-Bold.ttf') format('ttf'),
      url('../../assets/fonts/ComicSansMS-BoldItalic.ttf') format('ttf'),
      url('../../assets/fonts/ComicSansMS-Italic.ttf') format('ttf'),
}

@font-face {
  font-family: 'Courier New';
  src: url('../../assets/fonts/CourierNewPSMT.ttf') format('ttf'),
      url('../../assets/fonts/CourierNewPS-BoldMT.ttf') format('ttf'),
      url('../../assets/fonts/CourierNewPS-ItalicMT.ttf') format('ttf'),
      url('../../assets/fonts/CourierNewPS-BoldItalicMT.ttf') format('ttf'),
}

@font-face {
  font-family: 'Cormorant Garamond';
  src: url('../../assets/fonts/CormorantGaramond-Regular.ttf') format('ttf'),
      url('../../assets/fonts/CormorantGaramond-Bold.ttf') format('ttf'),
      url('../../assets/fonts/CormorantGaramond-BoldItalic.ttf') format('ttf'),
      url('../../assets/fonts/CormorantGaramond-Italic.ttf') format('ttf'),
}

@font-face {
  font-family: 'Geneva';
  src: url('../../assets/fonts/Geneva\ Regular.ttf') format('ttf'),
      url('../../assets/fonts/Geneva\ Normal.ttf') format('ttf'),
      url('../../assets/fonts/Geneva\ Bold-Italic.ttf') format('ttf'),
      url('../../assets/fonts/Geneva\ Normal-Italic.ttf') format('ttf'),
}

@font-face {
  font-family: 'Helvetica';
  src: url('../../assets/fonts/Helvetica.ttf') format('ttf'),
      url('../../assets/fonts/Helvetica-Bold.ttf') format('ttf'),
      url('../../assets/fonts/Helvetica-BoldOblique.ttf') format('ttf'),
      url('../../assets/fonts/Helvetica-Light.ttf') format('ttf'),
      url('../../assets/fonts/Helvetica-LightOblique.ttf') format('ttf'),
      url('../../assets/fonts/Helvetica-Oblique.ttf') format('ttf'),
}

@font-face {
  font-family: 'Impact';
  src: url('../../assets/fonts/impact.ttf') format('ttf'),
}

@font-face {
  font-family: 'Lato';
  src: url('../../assets/fonts/Lato-Regular.ttf') format('ttf'),
      url('../../assets/fonts/Lato-Bold.ttf') format('ttf'),
      url('../../assets/fonts/Lato-Italic.ttf') format('ttf'),
      url('../../assets/fonts/Lato-BoldItalic.ttf') format('ttf'),
}

@font-face {
  font-family: 'Lucida Sans Unicode';
  src: url('../../assets/fonts/l_10646.ttf') format('ttf'),
}

@font-face {
  font-family: 'Tahoma';
  src: url('../../assets/fonts/Tahoma.ttf') format('ttf'),
      url('../../assets/fonts/Tahoma-Bold.ttf') format('ttf'),
}

@font-face {
  font-family: 'Trebuchet MS';
  src: url('../../assets/fonts/trebuc.ttf') format('ttf'),
      url('../../assets/fonts/Trebuchet-MS-Italic.ttf') format('ttf'),
      url('../../assets/fonts/Lato-Italic.ttf') format('ttf'),
      url('../../assets/fonts/Lato-BoldItalic.ttf') format('ttf'),
}
