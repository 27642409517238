@import '../../../utils/styles/variables.scss';

.divider {
  width: 100%;
  height: 1px;
  background-color: $stroke-default;

  &__vertical {
    width: 1px;
    height: 100%;
  }
}
