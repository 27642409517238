.survey_table_item {
  &__icon {
    width: 16px;
    height: 16px;
  }

  &__total_wrapper {
    max-width: 200px;
  }

  &:hover {
    cursor: pointer;
  }
}