.pagination {
  position: relative;
  max-width: fit-content;
  margin: 0 auto 20px;

  &__buttons_section {
    max-width: fit-content;
  }

  &__arrow {
    padding: 10px;

    &_prev {
      transform: rotate(180deg);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__buttons_container {
    max-width: fit-content;
    padding: 0 20px;
  }

  &__number_of_total {
    font-size: 14px;
  }

  &__input_section {
    position: absolute;
    left: 110%;
  }
}