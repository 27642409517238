@import '../../utils/styles/mixins.scss';

.user_account_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 660px;

  &__data_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;

    margin: 12px 0;
  }

  &__data_wrapper {
    width: 50%;
  }

  &__data {
    font-size: 14px;

    @include onTablet {
      font-size: 16px;
    }
  }

  &__right_data {
    text-align: left;
    font-weight: 700;
  }

  &__left_data {
    text-align: right;
  }
}