@import '../../utils/styles/variables.scss';

.account_stat_item {
  padding: 15px 0;
  position: relative;

  &__max_width_wrapper {
    max-width: max-content;
  }

  &__date {
    font-size: 14px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba($light-green, 0) 0%, $light-green 30%, rgba($light-green, 0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover::before {
    opacity: 1;
  }
}