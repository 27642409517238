.editor_clickable_area_google_form {
  margin-top: 12px;

  &__title {
    font-size: 12px;
  }

  &__button {
    width: 100%;
    max-width: none;
    min-height: auto;
    height: 30px;
    
    & > span {
      font-size: 12px;
    }

    &_container {
      margin-top: 10px;
    }
  }
}

.iframe {
  width: 100%;
  height: 70vh;
  border: none;
  border-radius: 4px;
}