@import '../../../utils/styles/variables.scss';
@import '../../../utils/styles/mixins.scss';

.customInput {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;

  &_container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &_icon {
    position: absolute;
    top: 12px;
    left: 10px;
    width: 16px;
    height: 16px;

    &__editor_sidebar {
      top: 7px;
    }
  }

  &_input {
    width: 100%;
    height: 40px;
    padding: 10px;
    align-items: center;
    border: 1px solid $stroke-default;
    border-radius: 4px;
    outline: none;

    &::placeholder {
      color: $font-placeholder;
      font-size: 14px;
    }

    &:disabled {
      color: $font-placeholder;
      background-color: #fff;
    }

    &__error {
      border-color: red;
    }

    &:not(&__disabled) {
      &:hover {
        border-color: $font-active;
        cursor: text;
      }
    }
    
    &:focus {
      border-color: $font-active;
    }

    &__editor {
      height: 30px;
      padding: 6px;

      &::placeholder {
        font-size: 12px;
      }
    }
  }

  &__label {
    font-size: 14px;

    @include onTablet {
      font-size: 16px;
    }

    &_editor {
      font-size: 12px;
    }
  }

  &__loader {
    position: absolute;
    right: 150px;
  }

  &__right_icon {
    position: absolute;
    right: 10px;
    color: $font-placeholder;
    font-size: 12px;
  }

  &__autocomplete {
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 15;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid $stroke-default;
    background-color: #fff;
    width: 260px;

    &Item {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 240px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    cursor: text;
  }
}