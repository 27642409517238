.button {
  background-color: transparent;
  border: none;

  &__icon {
    transition: all 0.3s ease;
    width: 16px;
    height: 16px;
    
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
}