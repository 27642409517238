@import '../../utils/styles/mixins.scss';
@import '../../utils/styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height-mobile;
  padding: 0 20px;
  border-bottom: 1px solid #DBDCDF;
  background: #fff;

  &_rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    @include onTablet {
      gap: 20px;
    }
  }

  &_leftSide {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_linksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &_logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    
    @include onTablet {
      width: 40px;
      height: 40px;
    }
  }

  &_title {
    font-size: 14px;
    font-weight: 700;
    max-width: 120px;
    overflow: hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include onTablet {
      font-size: 18px;
      max-width: 240px;
    }

    @include onDesktop {
      max-width: 100%;
    }
  }

  &__popup_button {
    background-color: transparent;
    border: none;

    &_icon {
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      
      @include onTablet {
        width: 40px;
        height: 40px;
      }
    
      &:hover {
        cursor: pointer;
      }
    }
  }

  &_audience_plus {
    font-size: 20px;
    font-weight: 700;
  }

  &_popup{
    &__mobile {
      display: block;
      
      @include onTablet {
        display: none;
      }
    }

    &__desktop {
      display: none;
      
      @include onTablet {
        display: block;
      }
    }
  }
  
  &_audiences {
    display: none;

    @include onTablet {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  @include onTablet {
    height: $header-height;
  }
}