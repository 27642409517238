.user_account_page {
  &__header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin: 30px 0;
  }
}