.icon_with_text {
  padding-left: 10px;
    
  &__icon {
    width: 16px;
    height: 16px;
  }

  &__text {
    font-size: 12px;
  }
}