@import '../../../utils/styles/mixins.scss';
@import '../../../utils/styles/variables.scss';

.dropdownItem {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 5px;
  transition: all 0.3s ease;
  
  &_label {
    font-size: 14px;
    max-width: 140px;
    margin-left: 24px;
    overflow: hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include onTablet {
      max-width: 240px;
    }
  }

  &_selected {
    position: absolute;
    top: 55%;
    transform: translate(8px, -55%);
  }
  
  &:hover {
    cursor: pointer;
    background-color: $light-green;
  }
}