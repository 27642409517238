@import '../../utils/styles/variables.scss';
@import '../../utils/styles/mixins.scss';

@mixin onDesktop {
  @media (min-width: 864px) {
    @content;
  }
}

.start_page {
  &__wrapper {
    height: 100vh;
    padding: 30px;
    align-items: center;

    @include onDesktop {
      justify-content: start;
    }
  }

  &__main {
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
    
    @include onDesktop {
      width: 100%;
      flex-direction: row;
      align-items: center;
    }
  }

  &__divider {
    display: none;

    @include onDesktop {
      display: block;
    }
  }

  &__right_section {
    max-width: 490px;
  }

  &__title {
    font-family: 'Helvetica', sans-serif;
    font-weight: normal;
    font-size: 30px;
  }

  &__sub_title {
    position: relative;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    font-size: 14px;
    width: max-content;

    &::after {
      content: "";
      position: absolute;
      bottom: -4px;
      right: 0;
      height: 2px;
      width: 0%;
      background-color: $font-active;
      transition: 0.3s ease-in-out;
      border-radius: 12px;
    }

    &:hover::after {
      width: 100%;
      left: 0;
    }
  }

  &__main_logo {
    justify-content: center;

    @include onDesktop {
      width: 100%;
      justify-content: end;
    }

    &_image {
      width: 140px;

      @include onDesktop {
        width: 360px;
      }
    }
  }

  &__error {
    color: red;
    margin-top: -50px;
  }

  &__footer {
    margin-top: auto;

    & > * {
      color: #B0B5BC;
      font-size: 14px;
    }

    &_links {
      width: fit-content;
    }

    &_link {
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $primary;
      }
    }
  }
}
