.smart_form_preview {
  margin-top: 32px;

  &__success_title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__success_message {
    font-size: 20px;
    font-weight: 700;
  }
}