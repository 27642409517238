.text_link {
  font-size: 14px;
  color: #45869F;

  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    color: #13242b;
  }
}