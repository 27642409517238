@import '../../../utils/styles/variables.scss';
@import '../../../utils/styles/mixins.scss';

.dropdown {
  max-width: fit-content;
  position: relative;
  
  &__container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  &_input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid $stroke-default;
    background-color: #fff;
    width: 260px;

    &:hover {
      border-color: $font-active;
    }

    &__disabled:hover {
      border-color: $stroke-default;
    }
    
    &__focused {
      border-color: $font-active;
    }
  }

  &_inputText {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &_selectedItem {
    font-size: 14px;
    max-width: 120px;
    overflow: hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__disabled {
      color: $font-placeholder;
    }

    @include onTablet {
      max-width: 230px;
    }
  }

  &_list {
    position: absolute;
    width: 100%;
    max-width: 260px;
    z-index: 10;

    border: 1px solid $stroke-default;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;

    & > div {
      border-bottom: 1px solid $stroke-default;
    }

    & > div:last-child {
      border-bottom: none;
    }
  }
}