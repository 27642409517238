@import '../../utils//styles/typography.scss';
@import '../../utils//styles/mixins.scss';

.mailchimpConnectPage {
  display: flex;
  justify-content: space-between;

  &__radios_container {
    margin-bottom: 22px;
  }

  &_leftSection {
    display: flex;
    flex-direction: column;

    @include onTablet {
      max-width: 70%;
    }

    @include onDesktop {
      max-width: 50%;
    }
  }

  &_title {
    @extend %main-title-s;
    
    @extend %main-title-l;
  }

  &_description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    @include onTablet {
      font-size: 16px;
      max-width: 100%;
    }
  }

  &_listItem {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }

  &_list {
    margin-bottom: 20px;
  }

  &_helpTextContainer {
    display: flex;  
    gap: 10px;
    margin-bottom: 30px;
    align-items: center;  
  }

  &_questionmark {
    width: 25px;
    height: 25px;
  }

  &_helpText {
    line-height: 30px;
  }

  &_helpVideo {
    width: 600px;
    height: 360px;
    background: #D9D9D9;
  }
}