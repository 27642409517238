@import '../../utils/styles/typography.scss';
@import '../../utils/styles/mixins.scss';

.create_data_url_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;

  @include onTablet {
    max-width: 450px;
  }
  
  @include onDesktop {
    max-width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    @extend %main-title-l;
    @extend %main-title-s;

    align-self: start;
  }
}