@import '../../../utils/styles/variables.scss';

.checkbox {
  border-color: $font-active;
  width: 16px;
  height: 16px;

  &__container {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}