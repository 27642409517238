@import '../../utils/styles/variables.scss';

.surveys_editor_header {
  width: 100%;
  height: $editor-header;
  padding: 0 20px;
  border-bottom: 1px solid $stroke-default;
  z-index: 1;

  &__left_side_container {
    width: fit-content;
  }
  
  &__add_text {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    width: fit-content;
    height: fit-content;
  }

  &__button_icon {
    width: 16px;
    height: auto;

    &:hover {
      cursor: pointer;
    }
  }

  &__add_image {
    &_label {
      width: 16px;
      height: 16px;
    }

    &_input {
      display: none;
      width: 16px;
      height: 16px;
    }
  }
  
  &__right_side_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 60px;
    max-width: 135px;
    border: 1px solid $stroke-default;
    background-color: transparent;

    font-size: 12px;

    &__selected {
      border-color: $light-green;
      background-color: $light-green;
    }

    &__left {
      border-radius: 4px 0px 0px 4px;
    }

    &__right {
      border-radius: 0px 4px 4px 0px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}