.account_stat_item_date {
  align-self: start;
  max-width: fit-content;

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__date {
    white-space: nowrap;
    font-size: 14px;
  }
}