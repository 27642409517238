@import '../../utils/styles/variables.scss';

.surveys_editor_sidebar {
  &__section {
    position: relative;
    padding: 20px;
    gap: 15px;

    &_title {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      &_fixed {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: $app;
        padding: 20px 0 10px;
      }
    }

    &:not(.surveys_editor_sidebar__section_fixed) {
      height: calc((100vh - $editor-header - $survey-page-header - 1px) * (1 - $editor-layers-section-height));
      overflow-y: scroll;
    }

    &_layers {
      position: relative;
      overflow-y: scroll;
      gap: 0;
      padding-top: 0;
      height: calc((100vh - $editor-header - $survey-page-header - 1px) * $editor-layers-section-height);
    }
  }
}