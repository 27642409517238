@import './mixins.scss';

%main-title-s {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
}

%main-title-l {
  @include onTablet {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 34px;
  }
}