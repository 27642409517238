@import '../../utils/styles/typography.scss';
@import '../../utils/styles/mixins.scss';

.mailchimp_account_details_page {
  display: flex;
  flex-direction: column;

  &__title {
    @extend %main-title-s;
    @extend %main-title-l;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    
    width: 100%;
    max-width: 400px;
    margin-bottom: 44px;

    @include onTablet {
      font-size: 16px;
    }
  }
}