$app: #F9FBFD;
$stroke-default: #DBDCDF;
$font-active: #3B4147;
$font-placeholder: #B0B5BC;
$font-button: #45869F;
$light-green: #D5E4EA;
$primary: #FCBC05;
$green: #437E05;

$transition-time: 0.3s;

$survey-page-header: 71px;
$editor-header: 50px;
$editor-layers-section-height: 0.5;

$header-height: 70px;
$header-height-mobile: 50px;

$footer-height: 70px;
$footer-height-mobile: 115px;
