@import '../../utils/styles/mixins.scss';

.main {
  min-height: calc(100vh - 165px);
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  
  @include onTablet {
    padding: 70px;
    min-height: calc(100vh - 141px);
  }
  
  &__without_footer {
    min-height: calc(100vh - 50px);

    @include onTablet {
      min-height: calc(100vh - 70px);
    }
  }
}
