@import '../../utils/styles/variables.scss';

.surveys_editor_icon_box {
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid $stroke-default;
  background-color: #fff;
  transition: all 0.2s ease;
  background-position: center;
  background-repeat: no-repeat;

  &__selected {
    border-color: $light-green;
    background-color: $light-green;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }
}