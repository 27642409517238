@import '../../../utils/styles/variables.scss';

.textarea {
  &__input {
    padding: 10px;
    background: #fff;
    border: 1px solid $stroke-default;
    border-radius: 4px;
    resize: none;
    outline: none;

    font-family: Lato, sans-serif;
    font-size: 12px;
    color: $font-active;

    &::placeholder {
      color: $font-placeholder;
      font-family: inherit;
      font-size: inherit;
    }

    &:not(&_disabled):hover {
      border-color: $font-active;
    }

    &:not(&_disabled):focus {
      border-color: $font-active;
    }
  }
}