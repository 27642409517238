@import '../../utils/styles/mixins.scss';

.mailchimp-account-table {
  width: 100%;
  height: 40vh;
  
  &--desktop {
    display: none;
    max-width: 1240px;

    @include onTablet {
      display: block;
    }
  }

  &--mobile {
    display: block;
    max-width: 500px;
    margin: 0 auto;

    @include onTablet {
      display: none;
    }
  }
}
