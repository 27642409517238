@import '../../utils/styles/variables.scss';

.smart_form {
  &__right_side_buttons {
    width: fit-content;
  }

  &__title {
    align-self: flex-end;
    font-size: 30px;
  }

  &__right_side_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 60px;
    width: 100%;
    max-width: 150px;
    border: 1px solid $stroke-default;
    text-wrap: nowrap;
    background-color: transparent;

    font-size: 12px;

    &__selected {
      border-color: $light-green;
      background-color: $light-green;
    }

    &__left {
      border-radius: 4px 0px 0px 4px;
    }

    &__right {
      border-radius: 0px 4px 4px 0px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}