@import '../../utils/styles/variables.scss';

.support_page_form {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }

  &__radios_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 0 12px;
  }

  &__textarea {
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid $stroke-default;
    background: #fff;
    resize: none;
    outline: none;

    color: inherit;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      border-color: $font-active;
    } 

    &:focus {
      border-color: $font-active;
    }

    &::placeholder {
      font-size: 12px;
      color: $font-placeholder;
    }
  }
}