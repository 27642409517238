@import '../../utils/styles/variables.scss';
@import '../../utils/styles/mixins.scss';

.not_found_page {
  height: calc(100vh - $header-height-mobile - $footer-height-mobile);

  @include onTablet {
    height: calc(100vh - $header-height - $footer-height);
  }

  &__title {
    padding: 0 20px;
    text-align: center;
  }
}