@import '../../utils/styles/variables.scss';
@import '../../utils/styles/mixins.scss';

.navigation_popup  {
  position: absolute;
  width: 170px;
  background-color: $app;
  top: 44px;
  right: 36px;
  border-radius: 4px;
  border: 1px solid $stroke-default;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 15;
  height: max-content;

  @include onTablet {
    width: 200px;
    top: 60px;
    right: 50px;
  }

  &__main_links {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
  }

  &__link {
    font-size: 14px;
    text-align: left;

    padding: 10px 16px;
    background-color: $app;
    color: inherit;
    border: none;

    transition: all 0.3s ease;

    &:hover {
      background-color: $primary;
      cursor: pointer;
    }

    @include onTablet {
      padding: 12px 24px;
      font-size: 16px;
    }
  }

  &__sign_out_container {
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
  }
}