@import '../../utils/styles/variables.scss';

.editor {
  height: calc(100vh - 121px);
  width: calc(100vw - 310px);

  &__canvas {
    &_container {
      background: $app;
      width: calc(100vw - 310px);
      height: calc(100vh - 121px);
    }

    &_wrapper {
      width: calc(100vw - 310px);
    }
  }

  &__results {
    height: calc(100vh - 121px);
    width: calc(100vw - 480px);

    .editor__canvas {
      .editor__canvas_wrapper {
        background: $app;
        width: calc(100vw - 480px);
        height: calc(100vh - 121px);
      }

      .editor__canvas_container {
        width: calc(100vw - 480px);
      }
    }
  }
}