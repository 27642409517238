.survey_result_option {
  padding: 5px 0;

  &__left_side {
    max-width: fit-content;
  }

  &__title {
    font-size: 12px;
  }

  &__drag_handler {
    &:hover {
      cursor: pointer;
    }
  }
}