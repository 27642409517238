@import '../../../utils/styles/variables.scss';

.wrapper {
  width: fit-content;
}

.color_picker {
  position: relative;
  background-color: #fff;
  border: 1px solid $stroke-default;
  border-radius: 4px;
  width: max-content;
  padding: 2px;

  &__container {
    width: 144px;

    &:hover {
      cursor: pointer;
    }
  }

  &__color {
    min-width: 24px;
    min-height: 24px;
    border: 1px solid $stroke-default;
    border-radius: 4px;
  }

  &__color_title {
    font-size: 12px;
  }

  &__picker {
    position: absolute;
    left: 0;
    top: 34px;
    z-index: 50;
    width: 265px;
    border-radius: 4px;
    border: 1px solid $stroke-default;
    background-color: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }

  &__block_wrapper {
    max-width: fit-content;
    padding: 10px;
  }

  &__input_container {
    position: relative;

    &::after {
      content: '%';
      position: absolute;
      font-size: 12px;
      color: $font-placeholder;
      right: 10px;
    }

    &:hover {
      cursor: text;
    }
  }

  &__input {
    height: 30px;
    padding-left: 10px;
    border: 1px solid $stroke-default;
    outline: none;

    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 12px;
    
    &__left {
      width: 86px;
      border-radius: 4px 0px 0px 4px;
    }

    &__right {
      position: relative;
      width: 70px;
      border-radius: 0px 4px 4px 0px;
      border-left: none;
    }
  }

  &__text {
    font-size: 12px;
  }

  &__item {
    min-width: 20px;
    min-height: 20px;
    border-radius: 2px;
    border: 1px solid $stroke-default;

    &__big {
      min-width: 30px;
      min-height: 30px;
      border-radius: 4px;
    }

    &:not(&__big):not(&__selected):hover {
      border-color: $app;
      outline: 1px solid $light-green;
      cursor: pointer;
    }

    &:not(&__big):not(&__selected):active {
      border-color: $app;
      outline: 1px solid $font-button;
      cursor: pointer;
    }

    &__selected {
      border-color: $app;
      outline: 1px solid $font-button;
    }
  }

  &__label {
    font-size: 12px;
  }
}