@import '../../utils/styles/variables.scss';

.form_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid $stroke-default;
  height: $survey-page-header;
  background-color: #fff;

  &__settings {
    &:hover {
      cursor: pointer;
    }
  }

  &__copy_button {
    min-width: 260px;
    min-height: 40px;
    height: 40px;
  }
}