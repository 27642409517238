@import '../../../utils/styles/variables.scss';

.radio {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__input {
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    border: 2px solid $font-placeholder;
    border-radius: 50%;
    margin: 0;
    margin-right: 10px;
    transition: transform 0.3s ease;
    cursor: pointer;

    &:active {
      transform: scale(0.8);
    }

    &:checked {
      background-color: $app;
      border-width: 4px;
      border-color: $font-button;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
  }
}
