@import '../../utils/styles/variables.scss';

.editor_results_section_item {
  border: 2px solid $font-placeholder;
  background: #FFF;
  width: 130px;
  height: 80px;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  &__selected {
    border-color: $font-button;

    &:hover {
      cursor: default;
      box-shadow: none;
    }
  }

  &__title {
    font-size: 12px;
    color: $font-active;
    user-select: none;
    max-width: 48px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}