@import '../../utils/styles/variables.scss';
@import '../../utils/styles/mixins.scss';

.editor_embed_code {
  &__title {
    font-size: 14px;
    line-height: 20px;
  }

  &__code_container {
    overflow: auto;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid $stroke-default;
    margin-top: -10px;
    max-height: 100px;

    & > pre {
      color: $green;
      font-size: 12px;
      margin: 0;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  &__copy_btn_container {
    flex-direction: column;
    max-width: fit-content;
    align-items: end;

    @include onTablet {
      flex-direction: row;
    }
  }
}