.merge_tags_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  &__container {
    height: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__title {
      font-size: 12px;
    }
  }
}