.mailchimp_accounts_table_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  min-width: 100%;
  max-width: 610px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar { 
    display: none;
  }

  &__left_side {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;

    &__small {
      width: 16px;
      height: 16px;
    }

    &__without_border {
      border-radius: 0%;
    }
  }

  &__account_name {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  &__edit_button {
    z-index: 100;
    padding: 16px;
  }

  &:hover {
    cursor: pointer;
  }

  &__without_hover:hover {
    cursor: default;
  }
}