@import '../../../utils/styles/variables.scss';

.switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  display: flex;
  align-items: center;
  gap: calc(3.5em);

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .switch__slider {
      background-color: $font-button;
      border: 1px solid $font-button;
    }

    &:checked + .switch__slider:before {
      transform: translateX(1.4em);
      background-color: #fff;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid $stroke-default;
    transition: .3s;
    border-radius: 30px;

    &::before {
      position: absolute;
      content: "";
      height: 1.4em;
      width: 1.4em;
      border-radius: 20px;
      left: 0.27em;
      bottom: 0.22em;
      background-color: $stroke-default;
      transition: .3s;
    }
  }

  &__label {
    flex: auto;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    user-select: none;
  }
}
