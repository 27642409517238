@mixin onTablet {
  @media (min-width: 744px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1024px) {
    @content;
  }
}