.return_back_button {
  height: fit-content;
  background-color: transparent;
  border: none;

  &__icon {
    width: 17px;
    height: auto;

    &:hover {
      cursor: pointer;
    }
  }
}
