@import '../../utils/styles/mixins.scss';

.support_page_header {
  &__avatar {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }

  &__greeting {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: -15px;

    @include onTablet {
      font-size: 26px;
    }
  }
  
  &__questions {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
    
    @include onTablet {
      font-size: 16px;
    }
  }
}