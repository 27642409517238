.proportional_resize_button {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  
  &:hover {
    cursor: pointer;
  }

  &__icon {
    width: 13px;
    height: auto;
  }
}