@import '../../../utils/styles/variables.scss';

.right_side_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 60px;
  max-width: 135px;
  border: 1px solid $stroke-default;
  background-color: transparent;

  font-size: 12px;

  &__selected {
    border-color: $light-green;
    background-color: $light-green;
  }

  &__left {
    border-radius: 4px 0px 0px 4px;
  }

  &__right {
    border-radius: 0px 4px 4px 0px;
  }

  &:hover {
    cursor: pointer;
  }
}