@import '../../utils/styles/variables.scss';

.surveys_editor_sidebar_layer {
  padding: 7px 10px;
  background-color: $app;

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__title {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 140px;
    font-size: 12px;

    &__selected {
      font-weight: 700;
    }
  }

  &:hover {
    cursor: pointer;

    .surveys_editor_sidebar_layer__button_lock {
      animation: appear 0.3s ease;
      display: block;
    }
  }

  &__buttons_container {
    max-width: max-content;
  }

  &__button {
    padding: 5px;
    max-width: 22px;
    max-height: 26px;

    &_lock {
      display: none;
    }
  }

  &__selected {
    background-color: $light-green;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
