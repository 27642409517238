.button {
  background-color: #2465AF;
  color: white;
  border: none;
  outline: none;
  border-radius: 22px;
  font-size: 16px;
  width: 260px;
  height: 40px;
  padding-left: 20px;
  transition: all 0.3s ease-in-out;

  &__logo {
    width: 19px;
    height: 19px;
  }

  &__text {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: normal;
  }

  &:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  }

  &:active {
    background-color: #13447b;
  }
}