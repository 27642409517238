@import '../../../utils/styles/mixins.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  max-width: fit-content;
  min-height: 44px;
  padding: 5px 20px;
  border-radius: 6px;
  transition: all 0.3s ease;

  &__small {
    min-height: 100%;
    max-width: 100%;
    min-width: 120px;
    height: 32px;
  }
  
  &__extra_small {
    min-height: 100%;
    max-width: 100%;
    height: 24px;
  }

  &_title {
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    user-select: none;
    -ms-user-select: none;
    text-wrap: nowrap;

    @include onTablet {
      font-size: 16px;
    }

    &__small, &__extra_small {
      font-size: 12px;
    }
  }

  &_logo {
    margin-bottom: 2px;
  }

  &:not(&__disabled):hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
  }

  &:not(&__disabled):active {
    transform: scale(0.935);
  }
}