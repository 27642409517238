.support_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__wrapper {
    width: 100%;
    max-width: 660px;
  }
  
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}