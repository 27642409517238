@import '../../../utils/styles/mixins.scss';

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 160;
  padding: 0 15px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  overflow: hidden;
  
  &__content_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px 20px;
    background-color: #fff;
    max-width: 300px;
    min-height: 150px;
    border-radius: 5px;
    animation: appear 0.3s ease;
    max-height: 600px;
    overflow-y: auto;

    @include onTablet {
      min-width: 380px;
      max-width: 500px;
      width: 40vw;
    }

    &_unmount {
      animation: disappear 0.3s ease;
    }
  }

  &__top_actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
  }

  &__close_btn {
    height: 16px;
    width: 16px;
    padding-left: 15px;
    padding-bottom: 15px;
    box-sizing: unset;

    &:hover {
      cursor: pointer;
    }
  }

  &__title_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  &__title {
    font-size: 22px;
    margin-bottom: 15px;
  }

  &__button_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @include onTablet {
      flex-direction: row;
    } 
  }

  &__button {
    min-width: 260px;
    padding: 0;
    min-height: 36px;

    @include onTablet {
      min-height: 44px;
      padding: 5px 20px;
      min-width: auto;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0.3;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.5;
      transform: scale(0);
  }
}
