@import '../../utils/styles/mixins.scss';

.create_data_url_button {
  &__button {
    max-width: 100%;

    @include onTablet {
      max-width: 260px;
    }
  }
}