@import '../../../../utils/styles/variables.scss';

.pagination_button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid $stroke-default;
  background-color: #fff;
 
  font-size: 14px;
  font-weight: 400;

  &__active {
    background-color: $light-green;
    border-color: $light-green;
  }

  &:hover {
    cursor: pointer;
  }
}
