@import '../../utils/styles/variables.scss';
@import '../../utils/styles/mixins.scss';

.footer_info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include onTablet {
    flex-direction: row;
    gap: 20px;
  }

  & &__item {
    color: $app;
    font-size: 12px;
    font-weight: normal;
    transition: all 0.3s ease;
    padding: 6px 0;

    &__link {
      &:hover {
        color: $primary;
      }
    }

    @include onTablet {
      padding: 27px 0;
    }
  }

  &__dark &__item {
    color: $font-active;
  }
}