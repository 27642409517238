@import '../../utils/styles/typography.scss';
@import '../../utils/styles/mixins.scss';

.create_data_url_page {  
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 40px;
    
    @include onDesktop {
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      height: 300px;
    }
  }
}